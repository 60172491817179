.submit {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin: 0.5% 40%;
  cursor: pointer;
  font-size: medium;
}
.property {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  padding: 10px;
}
.fieldLabel {
  width: 200px;
  display: inline-block;
  overflow-wrap: normal;
}
.areaSelection {
  pointer-events: none;
  opacity: 0.4;
}
.styles.false {
  opacity: 0.9;
}
.dynamicForm {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.selectArea {
  cursor: pointer;
}
.removeArea {
  padding: 80px 0px 7px 42px;
  margin-right: 15px;
}
.addArea {
  padding: 80px 0px 7px 42px;
  margin-right: 9.4px;
}
.states {
  margin: 0px;
  padding: 0px;
}

.indentation {
  display: flex;
  margin: 0.98%;
}

.stateOptions {
  height: fit-content;
  /* margin: 6% 5%;
  margin-left: 12%;
  padding-left: 20px; */
}
.AreaBlock {
  min-width: 19%;
  max-width: max-content;
  border: 1px solid;
  padding-top: 12px !important;
  padding: 2% 0.5%;
  /* margin: 67px 25px; */
  margin: 2% 0% 0% 0%;
  max-height: 140px;
  overflow: auto;
  line-height: 2;
}

.ServiceHeading {
  position: fixed;
  background-color: aliceblue;
  /* margin: 0px important; */
}

.app {
  margin-left: 10px;
}
.App {
  /* background-color: antiquewhite; */
  font-size: 0.9rem;
}
.checkmark {
  position: absolute;
  top: 0;
  left: -28px;
  height: 25px;
  width: 25px;
  background-color: #140303;
}
.box {
  min-width: 114%;
  border: 1px solid;
  padding-right: 8.5%;
  padding-bottom: 0.5%;
  padding-left: 8.5%;
  margin: 4%;
  max-height: 183px;
  overflow: auto;
  height: 162px;
  min-height: 52px;
  line-height: 2;
  border-radius: 0px;
  padding-top: 14px !important;
}

.w3hide {
  /* display: none; */
  display: none !important;
  background-color: #6c757d;
}

.w3container {
  padding: 0.01em 16px;
}

.w3show {
  display: block !important;
}

.eduEmpBoxinner {
  /* border: 1px solid; */
  /* margin: 2%; */
  max-height: max-content;
  border-radius: 0px;
  width: 95%;
  padding: 10px;
  line-height: 2;
  padding-left: 0px;
}

.EduEmpBox {
  /* min-width: 80px; */
  /* border: 1px solid;
  padding-right: 8.5%;
  padding-bottom: 0.5%;
  padding-left: 8.5%;
  margin: 4%;
  max-height: 60%;
  overflow: auto;
  height: 120px;
  min-height: 52px;
  line-height: 2;
  border-radius: 0px;
  padding-top: 14px !important; */
  /* min-width: 80px; */

  margin-top: 20px;
  /* height: 25rem; */
  min-height: 100px;
  max-height: max-content;
}
.SelectBox {
  min-width: 86px;
  /* min-height: 101%; */
  max-width: fit-content;
  margin-left: 10%;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 3px;
  margin-bottom: 1px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.addingAreas {
  margin: 2%;
}
.gridContainer {
  display: grid;
  /* row-gap: 50px; */
  grid-template-columns: auto auto auto;
  /* background-color: #2196F3; */
  margin-left: 5%;
  padding: 10px;
  align-items: center;
  align-content: center;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.packageAllign {
  line-height: 1.5;
  padding-top: 2px;
  display: inline;
  margin-left: 25px;
}
.WidthSSN {
  width: 35px;
  margin: 5px;
}
.sameLineDiv {
  width: 100px;

  padding: 5px;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.displayModel {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modalcontent {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #676262;
  float: right;
  font-size: 32px;
  font-weight: bold;
  margin: -7px 9px 0px 0px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.createEducation, .createEmployment {
  text-align: center;
  max-width: fit-content;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  margin: 0 0 10px;
  cursor: pointer;
  padding: 4px;
}
