/* Spinner.css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  background: rgba(
    255,
    255,
    255,
    0.9
  ); /* White background with slight transparency */
  backdrop-filter: blur(4px); /* Apply blur effect */
  z-index: 1000; /* Ensure the spinner is on top */
}

.spinner {
  border: 4px solid #d3d3d3; /* Light grey */
  border-top: 4px solid #000000; /* Black */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
